// import { Cookies } from 'quasar'
import VueCookies from 'vue-cookies'
import { defineStore } from 'pinia'
import { LocalApi, MenuMasterModel } from 'mik-common/src/api-autogen/globalmenu'

import { installOptions } from '@gnb/modules/menus/main'

import {
  MenuApi,
  MenuItemModel,
  NavigationBarModel,
  BookmarkModel,
  LocalMenuModel
} from 'mik-common/src/api-autogen/globalmenu'

export const useGlobalMenuStore = defineStore('globalMenu', {
  persist: [
    {
      paths: ['menuData', 'siteMap', 'locItems', 'bookmarkList', 'navItems'],
      storage: sessionStorage
    }
  ],
  state: () => ({
    menuData: [] as MenuItemModel[],
    lnbMenuData: [] as MenuItemModel[],
    siteMap: {} as Record<string, string>,
    bucketId: installOptions.bucketId ?? import.meta.env.V_MENU_BUCKETID,
    locAllExpanded: false,
    // LocalMenu 중 현재 선택 된 메뉴명
    locTitle: '',
    // LocalMenu 중 선택 된 메뉴의 상위 메뉴명
    locDefaultTitle: '',
    // LocalMenu 상위 ID
    locDefaultMenuId: '',
    locSelectedItem: {} as LocalMenuModel,
    locExpandedItems: [] as LocalMenuModel[],
    locItems: [] as LocalMenuModel[],
    navItems: [] as NavigationBarModel[],
    bookmarkList: [] as BookmarkModel[],
    isLoading: false,
    navContainerHeight: '100%',
    isPopupShow: false,
    popupUrl: '',
    menuConfigData: {} as MenuMasterModel
  }),
  getters: {
    // locGetTitle(state) {
    //   return state.locTitle ? state.locTitle : state.locDefaultTitle
    // },
    navGetItems(state) {
      return state.navItems
    }
  },
  actions: {
    async fetchItems(langCode?: string, isForce: boolean = false, url?: string, whereIs?: string) {
      if (
        url &&
        url != '' &&
        !url.toLocaleLowerCase().startsWith('http:') &&
        !url.toLocaleLowerCase().startsWith('https:')
      ) {
        url = `${location.origin}/${location.pathname
          .split('/')
          .filter((s) => s)
          .shift()}${url}`
      }
      console.log('[global-menu.ts~before] isForce', isForce, 'staticUrl', url, 'whereIs', whereIs, 'lang', langCode)

      if (!this.isLoading) {
        this.isLoading = true

        if (this.menuData.length == 0 || isForce) {
          const langCodeVal =
            langCode != '' && langCode != undefined && langCode != null
              ? langCode
              : //@ts-ignore
                VueCookies.get('GWP_LANGUAGE_CODE') == null
                ? 'ko'
                : //@ts-ignore
                  VueCookies.get('GWP_LANGUAGE_CODE')

          //쿠키값을 가져와서 langCode가 '' 이 아닐때 값이 서로 다르면 isForce = true
          isForce = langCode != null && langCode != langCodeVal ? true : isForce
          const GlobalMenuApi = new MenuApi()
          const response = await GlobalMenuApi.v1MenuGlobalGet(
            this.bucketId,
            langCodeVal == null ? 'ko' : langCodeVal,
            url,
            '' // LocationId는 개인설정에서 바꾸는 경우만 있어서 공백으로 던짐
            //,           { withCredentials: true } //, headers: { 'Content-Type': 'application/json' }
          )
          console.log(
            '[global-menu.ts~after] isForce',
            isForce,
            'staticUrl',
            url,
            'whereIs',
            whereIs,
            'lang',
            langCode,
            'langCodeVal',
            langCodeVal
          )

          if (response.data.success && response.data.data) {
            const data = response.data

            this.clearMenuData()

            if (data.data?.menuData) this.menuData = data.data?.menuData
            if (data.data?.siteMap) this.siteMap = data.data?.siteMap
            if (data.data?.bookmarkList) this.bookmarkList = data.data?.bookmarkList
            if (data.data?.localMenuData) this.locItems = data.data?.localMenuData
            if (data.data?.selectedMenuHistory) this.navItems = data.data?.selectedMenuHistory
            if (data.data?.menuLnbData) this.lnbMenuData = data.data?.menuLnbData

            // LocalMenu 2레벨까지 펼치는 로직 이동
            if (this.navItems && this.navItems.length > 0) {
              for (let i = 0; i < this.navItems.length; i++) {
                if (i == this.navItems.length - 1) {
                  this.locUpdateSelectedItem({
                    id: this.navItems[i].menuId,
                    name: this.navItems[i].name,
                    isSelected: true,
                    url: this.navItems[i].link,
                    children: undefined
                  })
                }

                if (i == 0 || i == 1) continue

                if (
                  this.locExpandedItems.findIndex((item: LocalMenuModel) => item.id == this.navItems[i].menuId) == -1
                ) {
                  this.locExpandedItems.push({
                    id: this.navItems[i].menuId,
                    name: this.navItems[i].name,
                    isSelected: true,
                    url: this.navItems[i].link,
                    children: undefined
                  })
                }
              }
            }

            this.locGetData()

            await this.locGetMenuConfigData()

            console.log('[global-menu.ts!!] localMenuData length', this.locItems, 'staticUrl', url, 'whereIs', whereIs)
          }
        }
        this.isLoading = false
      }
    },
    locGetData() {
      let menuItems: LocalMenuModel[] = []
      menuItems = this.locItems
      const rootItem = menuItems && menuItems.length > 0 ? menuItems[0] : ({} as LocalMenuModel)
      const items = menuItems && menuItems.length > 0 ? menuItems[0].children : []

      this.locUpdateLocalMenuDefaultTitle(rootItem.name || '')
      this.locUpdateLocalMenuDefaultMenuId(rootItem.id || '')
      //this.locUpdateLocalMenuTitle(rootItem.name ?? '')
      if (items) this.locUpdateLocalMenuItems(items)
    },
    locUpdateLocalMenuItems(items: LocalMenuModel[]) {
      this.locItems = items
    },
    locUpdateAllExpanded(allExpanded: boolean) {
      this.locAllExpanded = allExpanded
    },
    // locUpdateLocalMenuTitle(title: string) {
    //   this.locTitle = title
    // },
    locUpdateLocalMenuDefaultTitle(title: string) {
      this.locDefaultTitle = title
    },
    locUpdateLocalMenuDefaultMenuId(menuId: string) {
      this.locDefaultMenuId = menuId
    },
    locUpdateSelectedItem(item: LocalMenuModel) {
      this.locSelectedItem = item
      this.locTitle = item.name ?? ''
      this.locSelectedItem.isSelected = true

      for (const menu of this.locItems) {
        if (menu.id === item.id) {
          return
        }

        const isChildren = (childrenItems: LocalMenuModel[]): boolean => {
          if (childrenItems != null) {
            for (const child of childrenItems) {
              if (child.id === item.id) {
                child.isSelected = true
                return true
              }
              if (child.children && isChildren(child.children)) {
                child.isSelected = true
              }
            }
          }
          return false
        }

        if (menu.children && isChildren(menu.children)) {
          menu.isSelected = true
        }
      }
    },
    locClearSelectedItem() {
      for (const menu of this.locItems) {
        menu.isSelected = false
        const children = (childrenItems: LocalMenuModel[]) => {
          if (childrenItems != null) {
            for (const child of childrenItems) {
              child.isSelected = false
              if (child.children) children(child.children)
            }
          }
        }
        if (menu.children) children(menu.children)
      }
    },

    navBookmarkId(menuId: string) {
      if (menuId != undefined && menuId != '') {
        const index = this.bookmarkList.findIndex((x) => {
          return x.menuId == menuId
        })
        if (index > -1) {
          return this.bookmarkList[index].id
        } else return ''
      } else {
        return ''
      }
    },
    navIsFavorite(bookmarkId: string) {
      return bookmarkId != undefined && bookmarkId != null && bookmarkId != '' ? true : false
    },
    locIsPopupShow(url: string, isPopup: boolean) {
      this.popupUrl = url
      this.isPopupShow = isPopup
    },
    clearMenuData() {
      this.menuData.splice(0)
      this.siteMap = {}
      this.bookmarkList.splice(0)
      this.locItems.splice(0)
      this.navItems.splice(0)
      this.lnbMenuData.splice(0)
    },
    async locGetMenuConfigData() {
      const menuId = this.locSelectedItem.id ?? ''

      if (menuId && this.bucketId) {
        const api = new LocalApi()
        const response = await api.v1LocalConfigurationGet(menuId, this.bucketId)
        this.menuConfigData = response.data.data ?? {}
      }
    }
  }
})

export interface IGlobalMenu {
  menuData: MenuItemModel[]
  lnbMenuData: MenuItemModel[]
  siteMap: Record<string, string>
  bucketId: string
  locAllExpanded: boolean
  locTitle: string
  locDefaultTitle: string
  locItems: LocalMenuModel[]
  locSelectedItem: LocalMenuModel
  locExpandedItems: LocalMenuModel[]
  navItems: NavigationBarModel[]
  navContainerHeight: string
  isPopupShow: boolean
  popupUrl: string
  menuConfigData: MenuMasterModel
}
