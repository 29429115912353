<template>
  <div id="lay_wrap">
    <menu-system :url="modeStore.getAlternativeUrl" v-if="modeStore.useGnbMenuMode" />
    <div id="lay_container">
      <q-ajax-bar
        ref="loadingBar"
        position="bottom"
        size="5px"
        :hijack-filter="ajaxFilter"
        @start="startLoadingBar"
        @stop="stopLoadingBar" />
      <local-menu :url="modeStore.getAlternativeUrl" v-show="modeStore.useLocalMenuMode" />
      <slot />
    </div>
    <site-footer v-show="modeStore.showFooterMode"></site-footer>
  </div>
</template>

<script setup lang="ts">
import SiteFooter from 'mik-common/src/components/layout/MikFooter.vue'
import { useModeStore } from 'mik-common/src/store/mode'
import { QAjaxBar } from 'quasar' // QAjaxBar 타입 가져오기

const modeStore = useModeStore()
const loadingBar = ref<QAjaxBar | null>(null)

// 2초 후에 로딩바를 강제종료 시킨다.
// 이유는 q-uploader에서 업로드 시 로딩바를 catch하지 못하여 계속 로딩하는 듯한 느낌이 들고 있다.
const startLoadingBar = () => {
  // setTimeout(() => {
  //   loadingBar.value.stop()
  // }, 2000)
}

const stopLoadingBar = () => {
  modeStore.showFooter = true
}

const ajaxFilter = (url: string) => {
  // console.log(url)
  // 알림센터는 로딩바를 보여주지 않는다.
  if (/(\/standbysystem)/.test(url)) {
    return false
  }
  return true
}

// q-ajax-bar를 외부에서 제어할 수 있는 함수들 정의
const startAjaxLoadingBar = (): void => {
  if (loadingBar.value) {
    loadingBar.value.start()
  }
}

const stopAjaxLoadingBar = (): void => {
  if (loadingBar.value) {
    loadingBar.value.stop()
  }
}

// 컴포넌트 외부로 노출할 수 있는 함수들을 provide
provide('startAjaxLoadingBar', startAjaxLoadingBar)
provide('stopAjaxLoadingBar', stopAjaxLoadingBar)

watch(
  () => modeStore.isPrintMode,
  (value) => {
    if (value) {
      const printMe = window
      setTimeout(() => {
        setTimeout(() => {
          printMe.focus()
          printMe.print()
        }, 500)
      }, 100)

      // 프린트 종료 후 print area element 제거
      printMe.onafterprint = () => {
        const printArea = printMe.parent.document.getElementsByClassName('mik_printing_area')[0]
        if (printArea) {
          printArea.remove()
        }
      }
    }
  }
)
</script>
<style>
/** Gnb css */
@import '@gnb/../public/css/gnb.css';
@import '@gnb/../public/css/lnb.css';

/** Popup Mode */
.popup_mode {
  padding: 0 !important;
  margin: 0 !important;
  max-width: 100% !important;
  width: 100% !important;
  min-width: auto !important;

  #lay_contents {
    margin-left: auto !important;
    padding-right: 0 !important;
  }
}
</style>
