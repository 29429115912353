<template>
  <!-- @vue-ignore -->
  <q-input v-model.trim="value" :readonly="isReadonly" dense :disable="isDisable" @keypress.enter.prevent="searchClick">
    <template #append>
      <q-icon v-show="isShowClear && value" name="clear" style="cursor: pointer" @click.prevent="clearClick">
        <q-tooltip>{{ $t('Reset') }}</q-tooltip>
      </q-icon>
      <q-icon name="search" style="cursor: pointer" @click.prevent="searchClick">
        <q-tooltip>{{ $t('Search') }}</q-tooltip>
      </q-icon>
    </template>
  </q-input>
</template>

<script setup lang="ts">
export interface Props {
  modelValue: string
  isReadonly?: boolean
  isDisable?: boolean
  clearClick?: () => void
  searchClick?: () => void
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: '',
  isReadonly: false,
  isDisable: false,
  clearClick: () => {},
  searchClick: () => {}
})

const value = computed({
  get() {
    return props.modelValue
  },
  set(val: string) {
    emit('update:modelValue', val)
  }
})

const isShowClear = ref<boolean>(!(props.clearClick == (() => {})))

const emit = defineEmits<{ (e: 'update:modelValue', value: string): void }>()
</script>
