import { useGlobalMenuStore } from '@gnb/store/modules/global-menu/global-menu'
import { useUserInfoStore } from '@gnb/store/modules/user-info/user-info'
import { storeToRefs } from 'pinia'
import { RecentlyMenuApi } from 'mik-common/src/api-autogen/globalmenu/api/recently-menu-api'

import { installOptions } from '@gnb/modules/menus/main'
import { RecentlyUsedMenuRequest } from 'mik-common/src/api-autogen/globalmenu/models/recently-used-menu-request'
import { BookmarkApi } from 'mik-common/src/api-autogen/globalmenu/api/bookmark-api'
import axios from 'axios'
import { Buffer } from 'buffer'
// import { useToast } from '@gnb/common/toast'
const t = { useI18n }
// const toast = useToast()
let instance: any = null
const apiRecently = new RecentlyMenuApi()

const createGlobalMenu = () => {
  // From Store
  const globalMenuStore = useGlobalMenuStore()
  const { siteMap } = storeToRefs(globalMenuStore)
  const userInfoStore = useUserInfoStore()
  const { userInfo } = storeToRefs(userInfoStore)

  const globalMenuClick = async (value: string, menuId: string) => {
    // let param = {
    //     RequestType: "InsertRecentlyUsedMenu",
    //     TenantId: mspEnvTenantId,
    //     BucketId: gwpGlobalData.MenuBucketId,
    //     MenuId: menuId
    // };
    // this.utils.ajax(
    //     mspEnvPortalWebUrl + "IF/CommonIF/CommonResponse.ashx",
    //     param,
    //     null,
    //     function () {
    //         gwpGlobalMenu.globalMenuClickInternal(unescape(value));
    //     },
    //     function () {
    //         gwpGlobalMenu.globalMenuClickInternal(unescape(value));
    //     }
    // );

    const param: RecentlyUsedMenuRequest = {
      menuId: menuId,
      bucketId: ''
    }
    await apiRecently.v1RecentlyMenuRecentlyUsedMenuPost(param)
    globalMenuClickInternal(unescape(value))
    await globalMenuStore.locGetMenuConfigData()
  }
  // move to menu
  const globalMenuClickInternal = function (d: string) {
    //모든링크 클릭시 Status 체크
    if (d.length && d.length > 0) {
      const p = d.split('$', 4)
      if (p.length == 4 && p[3].length && p[3].length > 0) {
        let u = ''
        if (p[0].length && p[0].length > 0 && siteMap.value[p[0]]) {
          u = siteMap.value[p[0]] + p[3]
        } else u = p[3]
        // 매크로 정의 처리
        let empid: string = ''
        const locid = ''
        let email: string = ''
        try {
          empid = userInfo.value.userID ?? ''
          // locid = this.userInfo..toLowerCase();
          email = userInfo.value.emailAddress?.toLowerCase() ?? ''
        } catch (e) {
          /* empty */
        }
        let url = u.replace(/{EmpID}/g, encodeURIComponent(empid))
        url = url.replace(/{Email}/g, encodeURIComponent(email))

        url = url.replace(/{base64EmpID}/g, encodeURIComponent(Buffer.from(empid).toString('base64')))
        //url = url.replace(/{base64EmpID}/g, encodeURIComponent(Base64.encode(empid)));
        url = url.replace(/{Location}/g, encodeURIComponent(locid))

        if (p[1] == '0') {
          // 페이지이동
          navigateToUrl(url)
        } else if (p[1] == '1') {
          // 새창
          // 이 때, 사이트 미사용인데 http 스키마가 없을 시 추가
          if (p[0].length === 0 && !url.match(/^https?:\/\//i)) {
            url = 'http://' + url
          }
          window.open(url, '_blank', p[2])
        } else if (p[1] == '2') {
          // 화면 중앙 새창
          globalMenuStore.locIsPopupShow(url, true)
        } else if (p[1] == '3') {
          // javascript 실행
          try {
            eval(url)
          } catch (e) {
            alert(e)
          }
        }
      } else if (d.indexOf('http') == 0) {
        navigateToUrl(d)
      }
    }
    return false
  }
  const gwpOpenCenter = (sURL: string, sName: string, sFeatures: string, isPopup: boolean = false) => {
    if (
      typeof sFeatures == 'string' &&
      sFeatures.length > 0 &&
      sFeatures.toLowerCase().indexOf('top') == -1 &&
      sFeatures.toLowerCase().indexOf('left') == -1
    ) {
      const features: string[] = sFeatures.split(/,/g)
      let width: string = '-1'
      let height: string = '-1'
      if (features.length && features.length > 0) {
        for (let idx = 0; idx < features.length; ++idx) {
          if (features[idx].toLowerCase().indexOf('width') != -1) {
            const w = features[idx].match(/\d+/g)
            if (w) width = w.toString()
          } else if (features[idx].toLowerCase().indexOf('height') != -1) {
            const h = features[idx].match(/\d+/g)
            if (h) height = h.toString()
          }
          if (width != '-1' && height != '-1') break
        }
      }
      const left = (window.screen.width - parseInt(width)) / 2
      const top = (window.screen.height - parseInt(height)) / 2
      sFeatures += ',left=' + left + ',top=' + top

      if (isPopup) {
        sURL += (sURL.includes('?') ? '&' : '?') + 'POPCLSID=Y'
      }
      try {
        const popup = window.open(sURL, sName, sFeatures)
        popup != null ? popup.focus() : null
      } catch (e) {
        /* empty */
      }
    } else {
      try {
        const popup = window.open(sURL, sName, sFeatures)
        popup != null ? popup.focus() : null
      } catch (e) {
        /* empty */
      }
    }
  }

  // open yrllow page
  const openYellowPage = (k: string) => {
    const portalHost = installOptions.baseUrl ?? ''

    const yellowpageHost = siteMap.value['SmartPortal.YellowPage']
      ? siteMap.value['SmartPortal.YellowPage']
      : '/YellowPage'

    const keyword = k
    const feature =
      'width=1020,height=618,directories=0,location=0,menubar=0,resizable=0,scrollbars=0,status=0,titlebar=0,toolbar=0'
    let address = ''

    if (keyword) {
      address = `${portalHost}${yellowpageHost}/search?category=ALL&keyword=${encodeURIComponent(keyword)}`
    } else {
      address = `${portalHost}${yellowpageHost}`
    }

    gwpOpenCenter(address, 'yellowpage', feature)
    sendYellowPageStatisticsLog()
  }

  return {
    siteMap,
    userInfo,
    globalMenuClick,
    openYellowPage,
    gwpOpenCenter,
    addFavorites,
    goToMain,
    updateFavoriteStatus
  }
}
/** 페이지 이동 router 처리 */
export const oldnavigateToUrl = (url: string) => {
  const router = installOptions.router
  if (router) {
    try {
      const baseUrl = `${location.hostname}${router.options.history.base.toLowerCase()}`.toLowerCase()
      //console.log(`baseUrl: ${baseUrl}`)
      if (
        baseUrl != '' &&
        url.toLowerCase().indexOf(`${location.protocol}//${location.hostname}`) > -1 &&
        url.toLowerCase().includes(baseUrl)
      ) {
        /** 이동하려는 페이지가 baseUrl이 포함되어 있는 경우 상대 경로만 추출
         * ex) http://local.portal.miksystem.com/Portal/Board/Notice -> /Notice
         */
        const pathname = url.toLowerCase().substring(url.toLowerCase().indexOf(baseUrl) + baseUrl.length)
        //console.log(`pathname: ${pathname}`)

        // URL이 router에 포함됨
        // force: true 추가 (동일 Url일 경우에도 router 이벤트가 발생함.)
        router.push({
          path: pathname,
          force: true
        })
      } else {
        window.location.href = url
      }
    } catch (e) {
      window.location.href = url
    }
  } else {
    window.location.href = url
  }
}

const isSameDomain = (url: string) => {
  return url.toLowerCase().indexOf(`${location.protocol}//${location.hostname}`) > -1
}

/** 페이지 이동 router 처리 */
export const navigateToUrl = async (url: string) => {
  const router = installOptions.router
  if (router) {
    try {
      const baseUrl = `${location.hostname}${router.options.history.base.toLowerCase()}`.toLowerCase()
      //console.log(`baseUrl: ${baseUrl}`)
      if (baseUrl != '' && isSameDomain(url)) {
        /** 이동하려는 페이지가 baseUrl이 포함되어 있는 경우 상대 경로만 추출
         * ex) http://local.portal.miksystem.com/Portal/Board/Notice -> /Notice
         */

        if (url.toLowerCase().indexOf(baseUrl) == -1) {
          window.location.href = url
        } else {
          let pathname = url.toLowerCase().substring(url.toLowerCase().indexOf(baseUrl) + baseUrl.length)
          //console.log(`pathname: ${pathname}`)

          if (pathname.includes(':8080')) {
            pathname = pathname.replace(':8080', '')
          }

          // URL이 router에 포함됨
          // force: true 추가 (동일 Url일 경우에도 router 이벤트가 발생함.)
          const result = await router.push({
            path: pathname,
            force: true,
            query: getRouterQuery(pathname)
          })

          // console.log(`router.push(${pathname})`)
          // console.log(result)

          // 페이지 이동이 실패했을 경우에는 href로 이동
          if (result) {
            window.location.href = url
          }
        }
      } else if (url.indexOf('/') == 0) {
        const result = await router.push({
          path: url,
          force: true
        })

        // 페이지 이동이 실패했을 경우에는 href로 이동
        if (result) {
          window.location.href = url
        }
      } else {
        window.location.href = url
      }
    } catch (e) {
      window.location.href = url
    }
  } else {
    window.location.href = url
  }
}

const getRouterQuery = (pathname: string) => {
  const query = {} as any
  if (pathname.indexOf('?') > -1) {
    const search = pathname.substring(pathname.indexOf('?'))
    const urlParams = new URLSearchParams(search)
    for (const [key, value] of urlParams) {
      query[key] = value
    }
  }
  return query
}

const useGwpGlobalMenu = () => {
  if (!instance) {
    instance = createGlobalMenu()
  }

  return instance
}

export const oldgoToMain = async () => {
  const frontUrl = installOptions.baseUrl ? installOptions.baseUrl + '/Front' : '/Front'
  document.location = frontUrl
}

export const goToMain = async () => {
  const frontUrl = installOptions.baseUrl ? installOptions.baseUrl + '/Front' : '/Front'

  try {
    const router = installOptions.router

    if (isSameDomain(frontUrl)) {
      if (router) {
        const baseUrl = `${location.hostname}${router.options.history.base.toLowerCase()}`.toLowerCase()
        const pathname = frontUrl.toLowerCase().substring(frontUrl.toLowerCase().indexOf(baseUrl) + baseUrl.length)

        const result = await router.push({
          path: pathname,
          force: true
        })

        // 페이지 이동이 실패했을 경우에는 href로 이동
        if (result) {
          window.location.href = frontUrl
        }
      }
    } else if (frontUrl == '/Front' && router) {
      const result = await router.push({
        name: 'Main',
        force: true
      })

      // 페이지 이동이 실패했을 경우에는 href로 이동
      if (result) {
        window.location.href = frontUrl
      }
    } else {
      document.location = frontUrl
    }
  } catch (e) {
    document.location = frontUrl
  }
}

const updateFavoriteStatus = () => {
  const menuRequest = ref<menuRequest>({
    name: '',
    url: document.location.href,
    menuId: ''
  })

  apiBook
    .v1BookmarkIsBookMarkListPost(menuRequest.value)
    .then((response) => {
      setTimeout(function () {
        // if (response.data.data == true) document.getElementById('MspSmartTalkFavoriteButton')?.classList.add('active')
        // else document.getElementById('MspSmartTalkFavoriteButton')?.classList.remove('active')
        if (response.data.data == true) document.getElementsByClassName('gwp_btn_fav')[0].classList.add('active')
        else document.getElementsByClassName('gwp_btn_fav')[0].classList.remove('active')
      }, 10)
    })
    .catch((err) => console.log(err))
}

const apiBook = new BookmarkApi()
interface menuRequest {
  name: string
  url: string
  menuId: string
}
const addFavorites = (menuId: string, menuUrl: string, currentMenuName: string) => {
  //add만
  // const menuRequest = ref<menuRequest>({
  //   name: currentMenuName,
  //   url: menuUrl,
  //   menuId: menuId ?? ''
  // })
  // let returnValue = ''
  // apiBook
  //   .v1BookmarkPost(menuRequest.value)
  //   .then((response) => {
  //     returnValue = response.data.data ?? ''
  //     toast.info(`${t('SaveSuccess')}`)
  //     console.log('즐겨찾기 추가', returnValue)
  //   })
  //   .catch((err) => console.log(err))

  //add delete
  const menuRequest = ref<menuRequest>({
    name: currentMenuName,
    url: menuUrl,
    menuId: menuId ?? ''
  })
  let returnValue = ''
  apiBook
    .v1BookmarkOnceApplyPost(menuRequest.value)
    .then((response) => {
      returnValue = response.data.data ?? ''
      if (returnValue == 'I') document.getElementsByClassName('gwp_btn_fav')[0].classList.add('active')
      else document.getElementsByClassName('gwp_btn_fav')[0].classList.remove('active')
      console.log('즐겨찾기 추가/삭제', returnValue)
    })
    .catch((err) => console.log(err))

  return returnValue
}

/** YellowPage 오픈 시 통계 로그 전송 */
const sendYellowPageStatisticsLog = async () => {
  await axios.get(`${import.meta.env.V_API_ORGCHART_PATH}/api/v1/Log/SetYellowPageLogQueue`)
}

export default useGwpGlobalMenu
