<template>
  <q-popup-proxy
    class="shadow-4 thisdfad"
    :model-value="props.modelValue"
    @before-show="handleOnBeforeShow"
    @before-hide="handleOnBeforeHide">
    <q-bar class="text-white" :class="themeClass">
      <div class="gt-md">{{ $t('MemberList', '회원 목록') }}</div>
    </q-bar>
    <q-virtual-scroll
      v-slot="{ item, index }"
      :items="memberList"
      class="memberList_content rounded-borders"
      style="max-height: 400px"
      @virtual-scroll="handleTableScroll">
      <q-item :key="index" v-ripple v class="q-mb-sm" clickable dense>
        <q-item-section avatar>
          <user-profile
            :user-id="item.empID"
            @click.stop="(e: MouseEvent) => showUserProfileCard(e, item.empID!)"></user-profile>
        </q-item-section>

        <q-item-section>
          <q-item-label>
            {{ item.displayName
            }}<q-icon
              v-if="item.empID == store.communityInfo.sysopUserId"
              :color="themeIconClass"
              name="school"
              style="margin: 0 0 2px 2px"
          /></q-item-label>
          <q-item-label caption lines="1">{{
            $t('RecentVisitDate', '최근 방문일') + ': ' + moment(item?.recentVisitDate).format('YYYY-MM-DD HH:mm')
          }}</q-item-label>
        </q-item-section>
      </q-item>
    </q-virtual-scroll>
    <!-- </q-list> -->
    <user-profile-card
      v-model="userProfileParam.visible"
      :user-id="userProfileParam.userId"
      :position="userProfileParam.position" />
  </q-popup-proxy>
</template>

<script setup lang="ts">
import UserProfile from 'mik-community/src/components/common/UserProfile.vue'
import UserProfileCard from 'mik-community/src/components/common/UserProfileCard.vue'
import { MemberApi, RequestMemberListModel, MemberModel, ResultMemberModel } from 'mik-common/src/api-autogen/community'
import { useUserProfileCard } from 'mik-community/src/composables/UserProfileCard'
import { useCommunityStore } from 'mik-community/src/store/community'
import { getThemeClass } from 'mik-community/src/utils/util'
import moment from 'moment'
const store = useCommunityStore()
const emit = defineEmits<{ (e: 'update:modelValue', value: boolean): void }>()

const loading = ref<boolean>(false)
const memberList = ref<MemberModel[]>([])

// 프로퍼티
interface Props {
  modelValue: boolean
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: false
})

// 페이징 정보
const pageSize: number = 5
let totalCount: number = 1
let pageNumber: number = 1

// API
const memberApi = new MemberApi()
const fnLoadMember = async () => {
  const req: RequestMemberListModel = {
    pageNumber: pageNumber,
    pageSize: pageSize
  }

  await memberApi.v1MemberListPost(store.communityInfo.communityId, req).then((response) => {
    if (response.data.success && response.data.data) {
      const result: ResultMemberModel = response.data.data

      result.memberList?.forEach((o) => memberList.value.push(o))
      totalCount = result.totalCount ?? 0

      pageNumber++
    }
  })
}
// 스크롤 액션
const handleTableScroll = async ({ index }: any) => {
  if (!loading.value && memberList.value.length < totalCount) {
    // 최초로드 or 마지막 추가 로드
    if (memberList.value.length <= pageSize || memberList.value.length == index + 1) {
      loading.value = true
    }

    if (loading.value) {
      try {
        await fnLoadMember()
      } catch (e) {
        totalCount = 0
      } finally {
        loading.value = false
      }
    }
  }
}

const { param: userProfileParam, showUserProfileCard } = useUserProfileCard()

// 초기화
const fnInit = () => {
  memberList.value = []

  totalCount = 1
  pageNumber = 1
}
const handleOnBeforeShow = () => {
  fnInit()
}

const handleOnBeforeHide = () => {
  emit('update:modelValue', true)
  userProfileParam.visible = false
}

// 팝업 호출 시
// onBeforeUpdate(async () => {
//   fnInit()
// })

const themeClass = ref(getThemeClass('lnb'))
const themeIconClass = ref(getThemeClass('icon'))
const themeTextClass = ref(getThemeClass('gnbText'))
</script>

<style scoped>
.memberList_content {
  width: 300px;
  margin: 20px 0;
  /* padding: 10px 0; */
  /* padding: 20px 10px 20px 5px; */
}
</style>
